import axios from 'axios';

export const baseUrl = process.env.REACT_APP_BACKEND_URL;
const googleApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

export const uploadedFilesUrl = process.env.REACT_APP_IMAGE_URL;
export const uploadedStaffFilesUrl = process.env.REACT_APP_STAFF_IMAGE_URL;

export const uploadedStaffAttachmentUrl = process.env.REACT_APP_STAFF_ATTACHMENT_URL;
export const uploadedChargeFilesUrl = process.env.REACT_APP_CHARGE_FILE_URL;

export const uploadedDogFilesUrl = process.env.REACT_APP_DOG_IMAGE_URL;
export const uploadedDogAttachmentUrl = process.env.REACT_APP_DOG_ATTACHMENT_URL;

export const uploadedSiteFilesUrl = process.env.REACT_APP_SITE_IMAGE_URL;
export const uploadedSiteAttachmentUrl = process.env.REACT_APP_SITE_ATTACHMENT_URL;

export const uploadedVehicleFilesUrl = process.env.REACT_APP_VEHICLE_IMAGE_URL;
export const uploadedVehicleAttachmentUrl = process.env.REACT_APP_VEHICLE_ATTACHMENT_URL;

axios.defaults.baseURL = baseUrl;

const authHeader = () => {
	const user = JSON.parse(localStorage.getItem('authed'));
	if (user && user.jwtToken) {
		return { Authorization: `Bearer ${user.jwtToken}`, siteId: user.siteId };
	}
	return {};
};

export const getLatLngByZipCode = (postCode) => {
	return axios.get(
		`https://maps.googleapis.com/maps/api/geocode/json?key=${googleApiKey}&address=${postCode}`,
	);
};

export const StaffLogin = (values) => {
	return axios.post(`/api/Users/StaffLogin`, values, { headers: authHeader() });
};

export const forgotPassword = (values) => {
	return axios.post(`/api/Users/ForgotPassword`, values, { headers: authHeader() });
};

export const resetPassword = (values) => {
	return axios.post(`/api/Users/ResetPassword`, values, { headers: authHeader() });
};

export const loginUser = (values) => {
	return axios.post(`/api/Users/login`, values, { headers: authHeader() });
};

export const SignUp = (values) => {
	return axios.post(`/api/Users/SignUp`, values, { headers: authHeader() });
};

export const getDogByName = (searchValue) => {
	return axios.get(`/api/Dog/GetDogByName?dogName=${searchValue}`, {
		headers: authHeader(),
	});
};

export const Search = (data) => {
	return axios.get(
		`/api/Dog/Search?key=${data.key}&searchTerm=${data.searchTerm}&siteId=${data.siteId}`,
		{
			headers: authHeader(),
		},
	);
};

export const ConnectDropbox = () => {
	return axios.get(`/api/Dropbox/Connect`, {
		headers: authHeader(),
	});
};

export const getAllDog = (values, address) => {
	return axios.get(
		`/api/Dog/GetAllDogs${values ? `?rateType=${values}` : ''}${
			address ? `?includeAddress=${address}` : ''
		}`,
		{ headers: authHeader() },
	);
};

export const getPendingDogsCount = () => {
	return axios.get(`/api/PendingDog/GetPendingDogsCount`, { headers: authHeader() });
};

export const getAllPendingDogs = () => {
	return axios.get(`/api/PendingDog/GetAllPendingDogs`, { headers: authHeader() });
};

export const GetPendingDogById = (id) => {
	return axios.get(`/api/PendingDog/GetPendingDogById/${id}`, {
		headers: authHeader(),
	});
};

export const getDogById = (id) => {
	return axios.get(`/api/Dog/GetDogById/${id}`, { headers: authHeader() });
};

export const getDogsOnRoute = (id) => {
	return axios.get(`/api/Dog/GetDogsOnRoute?routeId=${id}`, { headers: authHeader() });
};

export const updateDogsPosition = (values, obj) => {
	return axios.post(
		`/api/Dog/UpdateDogsPosition?currentDogId=${obj.dogId}&newRouteId=${obj.routeId}`,
		values,
		{ headers: authHeader() },
	);
};

export const getDogNote = (id) => {
	return axios.get(`/api/Dog/GetDogNotes/${id}`, { headers: authHeader() });
};

export const createDog = (values) => {
	return axios.post(`/api/Dog/CreateDog`, values, { headers: authHeader() });
};

export const createDogNote = (values) => {
	return axios.post(`/api/Dog/CreateDogNote`, values, { headers: authHeader() });
};

export const UpdateSite = (values, id) => {
	return axios.post(`/api/Site/UpdateSite?id=${id}`, values, { headers: authHeader() });
};

export const getSiteById = (id) => {
	return axios.get(`/api/Site/GetSiteById/${id}`, { headers: authHeader() });
};

export const getAllSite = () => {
	return axios.get(`/api/Site/GetAllSites`, { headers: authHeader() });
};
export const createSiteNote = (values) => {
	return axios.post(`/api/Site/CreateSiteNote`, values, { headers: authHeader() });
};

export const getSiteNote = (id) => {
	return axios.get(`/api/Site/GetSiteNotes/${id}`, { headers: authHeader() });
};

export const getStaffNote = (id) => {
	return axios.get(`/api/Staff/GetStaffNotes/${id}`, { headers: authHeader() });
};

export const createStaff = (values) => {
	return axios.post(`/api/staff/CreateStaff`, values, { headers: authHeader() });
};

export const UpdateStaff = (values, id) => {
	return axios.put(`/api/Staff/UpdateStaff?id=${id}`, values, {
		headers: authHeader(),
	});
};

export const UpdateUser = (values, id) => {
	return axios.put(`/api/Users/UpdateUser?id=${id}`, values, {
		headers: authHeader(),
	});
};

export const createStaffNote = (values) => {
	return axios.post(`/api/Staff/CreateStaffNote`, values, { headers: authHeader() });
};

export const getAllStaff = () => {
	return axios.get(`/api/staff/GetAllStaff`, { headers: authHeader() });
};

export const GetUserAll = () => {
	return axios.get(`/api/Users/GetUserAll`, { headers: authHeader() });
};

export const GetRoles = () => {
	return axios.get(`/api/Users/GetRoles`, { headers: authHeader() });
};

export const getAllHosts = () => {
	return axios.get(`/api/staff/GetAllHosts`, { headers: authHeader() });
};

export const cancelBooking = (values) => {
	return axios.post(`/api/Booking/CancelBooking`, values, { headers: authHeader() });
};

export const CancelFixedBookings = (values, dogId) => {
	return axios.post(`/api/Booking/CancelFixedBookings?dogId=${dogId}`, values, {
		headers: authHeader(),
	});
};

export const SaveFixedBookingOccurance = (values) => {
	return axios.post(`/api/Booking/SaveFixedBookingOccurance`, values, {
		headers: authHeader(),
	});
};

export const SaveSchedule = (values) => {
	return axios.post(`/api/Scheduler/SaveSchedule`, values, { headers: authHeader() });
};

export const SavePaddocks = (values) => {
	return axios.post(`/api/Paddock/SavePaddocks`, values, { headers: authHeader() });
};

export const updateStaffNote = (values) => {
	return axios.put(`/api/Staff/UpdateStaffNote?id=${values.id}`, values, {
		headers: authHeader(),
	});
};

export const GetVaccinatedDogsCount = () => {
	return axios.get(`/api/Vaccination/GetVaccinatedDogsCount`, {
		headers: authHeader(),
	});
};

export const GetVaccinatedDogs = () => {
	return axios.get(`/api/Vaccination/GetVaccinatedDogs`, { headers: authHeader() });
};

export const getAllVan = () => {
	return axios.get(`/api/Vehicle/GetAllVehicle`, {
		headers: authHeader(),
	});
};

export const createVan = (values) => {
	return axios.post(`/api/Vehicle/CreateVehicle`, values, {
		headers: authHeader(),
	});
};

export const getVanById = (id) => {
	return axios.get(`/api/Vehicle/GetVehicleById?id=${id}`, {
		headers: authHeader(),
	});
};

export const getVanNote = (id) => {
	return axios.get(`/api/Vehicle/GetVehicleNotes/${id}`, { headers: authHeader() });
};

export const createVanNote = (values) => {
	return axios.post(`/api/Vehicle/CreateVehicleNote`, values, {
		headers: authHeader(),
	});
};

export const updateVanNote = (values) => {
	return axios.put(`/api/Vehicle/UpdateVehicleNote?id=${values.id}`, values, {
		headers: authHeader(),
	});
};

export const updateVan = (values) => {
	return axios.put(`/api/Vehicle/UpdateVehicle?id=${values.id}`, values, {
		headers: authHeader(),
	});
};

export const updateDogNote = (values) => {
	return axios.put(`/api/Dog/UpdateDogNote?id=${values.dogId}`, values, {
		headers: authHeader(),
	});
};

export const updateSiteNote = (values) => {
	return axios.put(`/api/Site/UpdateSiteNote?id=${values.id}`, values, {
		headers: authHeader(),
	});
};

export const GetAttachmentType = () => {
	return axios.get(`/api/Dog/GetAttachmentType`, { headers: authHeader() });
};

export const GetStatus = () => {
	return axios.get(`/api/Dog/GetStatus`, { headers: authHeader() });
};

export const GetFeedType = () => {
	return axios.get(`/api/Dog/Getfeedtype`, { headers: authHeader() });
};

export const getAllRoute = () => {
	return axios.get(`/api/Route/GetAllRoutes`, { headers: authHeader() });
};

export const getRouteById = (id) => {
	return axios.get(`/api/Route/GetRouteById?id=${id}`, { headers: authHeader() });
};

export const createRoute = (values) => {
	return axios.post(`/api/Route/CreateRoute`, values, { headers: authHeader() });
};

export const updateRoute = (values) => {
	return axios.post(`/api/Route/UpdateRoute?id=${values.id}`, values, {
		headers: values.siteId ? { ...authHeader(), siteId: values.siteId } : authHeader(),
	});
};

export const deleteRoutes = (id) => {
	return axios.delete(`/api/Route/DeleteRouteById?id=${id}`, {
		headers: authHeader(),
	});
};

export const DeleteSchedule = (values) => {
	return axios.delete(
		`/api/Scheduler/DeleteSchedule?siteId=${values.siteId}&scheduleDate=${values.scheduleDate}`,
		{
			headers: authHeader(),
		},
	);
};

export const updateDog = (values) => {
	return axios.put(`/api/Dog/UpdateDog?id=${values.id}`, values, {
		headers: authHeader(),
	});
};

export const UpdatePendingDog = (values) => {
	return axios.post(`/api/PendingDog/UpdatePendingDog?id=${values.id}`, values, {
		headers: authHeader(),
	});
};

export const ApprovePendingDog = (id) => {
	return axios.post(`/api/PendingDog/ApprovePendingDog?pendingDogId=${id}`, {
		headers: authHeader(),
	});
};

export const deleteStaff = (id) => {
	return axios.delete(`/api/Staff/DeleteStaff?id=${id}`, {
		headers: authHeader(),
	});
};

export const DeleteUser = (id) => {
	return axios.delete(`/api/Users/DeleteUser?userId=${id}`, {
		headers: authHeader(),
	});
};

export const deleteDog = (id) => {
	return axios.delete(`/api/Dog/DeleteDog?id=${id}`, { headers: authHeader() });
};

export const deletePendingDog = (id) => {
	return axios.delete(`/api/PendingDog/DeletePendingDog?id=${id}`, {
		headers: authHeader(),
	});
};

export const deleteSite = (id) => {
	return axios.delete(`/api/Site/DeleteSite?id=${id}`, { headers: authHeader() });
};

export const getBookingsForDog = (values) => {
	return axios.get(
		`/api/Booking/GetBookingsForDog?dogId=${values.dogId}&month=${values.month}&year=${values.year}&siteId=${values.siteId}&includeSiteOffDays=true`,
		{
			headers: authHeader(),
		},
	);
};

export const GetBookingFrequencyByDog = (id) => {
	return axios.get(`/api/Booking/GetBookingFrequencyByDog?dogId=${id}`, {
		headers: authHeader(),
	});
};

export const getSiteEvents = (values) => {
	return axios.get(
		`/api/Site/GetSiteEvents?siteId=${values.siteId}&month=${values.month}&year=${values.year}`,
		{ headers: authHeader() },
	);
};

export const addSiteEvent = (values) => {
	return axios.post(`/api/Site/AddSiteEvent`, values, { headers: authHeader() });
};

export const GetDailyDiary = (date) => {
	return axios.get(`/api/DailyDiary/GetDailyDiary?date=${date}`, {
		headers: authHeader(),
	});
};

export const getVehicleEventById = (id) => {
	return axios.get(`/api/Vehicle/GetVehicleEventById?id=${id}`, {
		headers: authHeader(),
	});
};

export const getStaffEventById = (id) => {
	return axios.get(`/api/Staff/GetStaffEventById?id=${id}`, { headers: authHeader() });
};

export const updateStaffEvent = (values) => {
	return axios.post(`/api/Staff/UpdateStaffEvent`, values, {
		headers: authHeader(),
	});
};

export const cancelVehicleEvent = (id, series, identifier) => {
	return axios.post(
		`/api/Vehicle/CancelVehicleEvent?Id=${id}&cancelSeries=${series}&identifier=${identifier}`,
		{
			headers: authHeader(),
		},
	);
};

export const cancelStaffEvent = (id, series, identifier) => {
	return axios.post(
		`/api/Staff/CancelStaffEvent?Id=${id}&cancelSeries=${series}&identifier=${identifier}`,
		{
			headers: authHeader(),
		},
	);
};

export const createSite = (values) => {
	return axios.post(`/api/Site/CreateSite`, values, { headers: authHeader() });
};

export const getBookingPerDayByMonth = (values, id) => {
	return axios.get(
		`/api/Booking/GetBookingPerDayByMonth?month=${values.month}&year=${values.year}&siteId=${values.siteId}&dogId=${id}&includeSiteOffDays=true`,
		{ headers: authHeader() },
	);
};

export const GetCalendarEvents = (values) => {
	return axios.get(
		`/api/Calendar/GetCalendarEvents?siteId=${values.siteId}&date=${values.date}`,
		{ headers: authHeader() },
	);
};

export const GetFixedBookingsOverview = () => {
	return axios.get(`/api/Booking/GetFixedBookingsOverview`, {
		headers: authHeader(),
	});
};

export const GetForwardBookingReport = (date) => {
	return axios.get(`/api/Booking/GetForwardBookingReport?date=${date}`, {
		headers: authHeader(),
	});
};

export const GetSiteMemos = (values) => {
	return axios.get(`/api/Site/GetSiteMemos?siteId=${values.siteId}&date=${values.date}`, {
		headers: authHeader(),
	});
};

export const GetSleepOverBookingsForMonth = (values) => {
	return axios.get(
		`/api/Booking/GetSleepOverBookingsForMonth?month=${values.month}&year=${values.year}`,
		{ headers: authHeader() },
	);
};

export const getBookingOptions = () => {
	return axios.get(`/api/Booking/GetBookingOptions`, { headers: authHeader() });
};

export const getBookingById = (id) => {
	return axios.get(`/api/Booking/GetBookingById?id=${id}`, { headers: authHeader() });
};

export const getBookingsForDateRange = (values) => {
	return axios.get(
		`/api/Booking/GetBookingsForDateRange?dogId=${values.dogId}&siteId=${values.siteId}&startDate=${values.startDate}&endDate=${values.endDate}&includeSiteOffDays=true`,
		{ headers: authHeader() },
	);
};

export const getFixedBookingDetails = (id) => {
	return axios.get(`/api/Booking/GetFixedBookingDetails?dogId=${id}&bookingId=${3446}`, {
		headers: authHeader(),
	});
};

export const uploadSiteImage = (values) => {
	const body = new FormData();
	body.append('file', values);
	return axios.post(`/api/Site/UploadImage`, body, {
		headers: authHeader(),
		'Content-Type': 'multipart/form-data',
	});
};

export const SiteUploadFile = (values) => {
	const body = new FormData();
	body.append('file', values);
	return axios.post(`/api/Site/UploadFile`, body, {
		headers: authHeader(),
		'Content-Type': 'multipart/form-data',
	});
};

export const VehicleUploadFile = (values) => {
	const body = new FormData();
	body.append('file', values);
	return axios.post(`/api/Vehicle/UploadFile`, body, {
		headers: authHeader(),
		'Content-Type': 'multipart/form-data',
	});
};

export const UploadFile = (values) => {
	const body = new FormData();
	body.append('file', values);
	return axios.post(`/api/Dog/UploadFile`, body, {
		headers: authHeader(),
		'Content-Type': 'multipart/form-data',
	});
};

export const uploadStaffFile = (values) => {
	const body = new FormData();
	body.append('file', values);
	return axios.post(`/api/Staff/Upload`, body, {
		headers: authHeader(),
		'Content-Type': 'multipart/form-data',
	});
};

export const uploadStaffImage = (values) => {
	const body = new FormData();
	body.append('file', values);
	return axios.post(`/api/Staff/UploadImage`, body, {
		headers: authHeader(),
		'Content-Type': 'multipart/form-data',
	});
};

export const uploadImage = (values) => {
	const body = new FormData();
	body.append('file', values);
	return axios.post(`/api/Dog/UploadImage`, body, {
		headers: authHeader(),
		'Content-Type': 'multipart/form-data',
	});
};

export const uploadVanImage = (values) => {
	const body = new FormData();
	body.append('file', values);
	return axios.post(`/api/Vehicle/UploadImage`, body, {
		headers: authHeader(),
		'Content-Type': 'multipart/form-data',
	});
};

export const CreateFixedBooking = (values) => {
	return axios.post(`/api/Booking/SaveFixedBooking`, values, { headers: authHeader() });
};

export const updateFixedBooking = (values) => {
	return axios.post(`/api/Booking/UpdateFixedBooking`, values, {
		headers: authHeader(),
	});
};

export const updateVehicleEvent = (values) => {
	return axios.post(`/api/Vehicle/UpdateVehicleEvent`, values, {
		headers: authHeader(),
	});
};

export const updateBookingNotes = (values) => {
	return axios.post(`/api/Booking/UpdateBookingNotes`, values, {
		headers: authHeader(),
	});
};

export const CreateAdHocBooking = (values) => {
	return axios.post(`/api/Booking/SaveAdHocBooking`, values, { headers: authHeader() });
};

export const EditFixedBooking = (values) => {
	return axios.post(`/api/Booking/EditFixedBooking`, values, { headers: authHeader() });
};

export const CreateGroomBooking = (values) => {
	return axios.post(`/api/Booking/SaveGroomBooking`, values, { headers: authHeader() });
};

export const SaveSleepOverBooking = (values) => {
	return axios.post(`/api/Booking/SaveSleepOverBooking`, values, {
		headers: authHeader(),
	});
};

export const deleteDogNote = (id) => {
	return axios.delete(`/api/Dog/DeleteDogNote?id=${id}`, {
		headers: authHeader(),
	});
};

export const deleteVanNote = (id) => {
	return axios.delete(`/api/Vehicle/DeleteVehicleNote?id=${id}`, {
		headers: authHeader(),
	});
};

export const deleteSiteNote = (id) => {
	return axios.delete(`/api/Site/DeleteSiteNote?id=${id}`, {
		headers: authHeader(),
	});
};

export const getVehicleCalendarEventsByVehicleId = (values) => {
	if (values.vehicleId) {
		return axios.get(
			`/api/Vehicle/GetVehicleCalendarEventsByVehicleId?vehicleId=${values.vehicleId}&month=${values.month}&year=${values.year}&siteId=${values.siteId}&includeSiteOffDays=true`,
			{ headers: authHeader() },
		);
	}
	return axios.get(
		`/api/Vehicle/GetVehicleCalendarEventsByVehicleId?month=${values.month}&year=${values.year}&siteId=${values.siteId}&includeSiteOffDays=true`,
		{ headers: authHeader() },
	);
};

export const GetAllDrivers = (date) => {
	return axios.get(`/api/Staff/GetAvailableStaff?date=${date}`, {
		headers: authHeader(),
	});
};

export const GetAvailableVehicles = (date) => {
	return axios.get(`/api/Vehicle/GetAvailableVehicles?date=${date}`, {
		headers: authHeader(),
	});
};

export const GetSchedule = (values) => {
	return axios.get(
		`/api/Scheduler/GetSchedule?siteId=${values.siteId}&date=${values.date}&isSnapshot=${values.isSnapshot}`,
		{
			headers: authHeader(),
		},
	);
};

export const GetFeedList = (values) => {
	return axios.get(`/api/Dog/GetFeedList?siteId=${values.siteId}&date=${values.date}`, {
		headers: authHeader(),
	});
};

export const addVehicleEvent = (values) => {
	return axios.post(`/api/Vehicle/AddVehicleEvent`, values, { headers: authHeader() });
};

export const getStaffEvents = (values) => {
	if (values.staffId) {
		return axios.get(
			`/api/Staff/GetStaffCalendarEventsByStaffId?staffId=${values.staffId}&month=${values.month}&year=${values.year}&siteId=${values.siteId}&includeSiteOffDays=true`,
			{ headers: authHeader() },
		);
	}
	return axios.get(
		`/api/Staff/GetStaffCalendarEventsByStaffId?month=${values.month}&year=${values.year}&siteId=${values.siteId}&includeSiteOffDays=true`,
		{ headers: authHeader() },
	);
};

export const GetDailyPaddocks = (values) => {
	return axios.get(`/api/Paddock/GetDailyPaddocks?siteId=${values.siteId}&date=${values.date}`, {
		headers: authHeader(),
	});
};

export const GetDogInvoicesByMonthYear = (values) => {
	return axios.get(
		`/api/Invoice/GetDogInvoicesByMonthYear?month=${values.month}&year=${values.year}&siteId=${values.siteId}`,
		{ headers: authHeader() },
	);
};

export const PreviewInvoiceByDogAndMonthYear = (values) => {
	return axios.get(
		`/api/Invoice/PreviewInvoiceByDogAndMonthYear?dogId=${values.dogId}&month=${values.month}&year=${values.year}`,
		{ headers: authHeader() },
	);
};

export const addStaffEvent = (values) => {
	return axios.post(`/api/Staff/AddStaffEvent`, values, { headers: authHeader() });
};

export const getSiteEventById = (id) => {
	return axios.get(`/api/Site/GetSiteEventById?id=${id}`, { headers: authHeader() });
};

export const updateSiteEvent = (values) => {
	return axios.post(`/api/Site/UpdateSiteEvent`, values, {
		headers: authHeader(),
	});
};

export const cancelSiteEvent = (id, series, identifier) => {
	return axios.post(
		`/api/Site/CancelSiteEvent?Id=${id}&cancelSeries=${series}&identifier=${identifier}`,
		{
			headers: authHeader(),
		},
	);
};

export const deleteStaffNote = (id) => {
	return axios.delete(`/api/Staff/DeleteStaffNote?id=${id}`, {
		headers: authHeader(),
	});
};

export const getPrice = (id) => {
	return axios.get(`/api/Pricing/GetPricing?siteId=${id}`, {
		headers: authHeader(),
	});
};

export const GetFeedGroups = (value) => {
	return axios.get(`/api/Dog/GetFeedGroups?showOnlyActiveGroups=${value}`, {
		headers: authHeader(),
	});
};

export const CreateFeedGroup = (values) => {
	return axios.post(`/api/Dog/CreateFeedGroup`, values, {
		headers: values.siteId ? { ...authHeader(), siteId: values.siteId } : authHeader(),
	});
};

export const DeleteFeedGroup = (id) => {
	return axios.delete(`/api/Dog/DeleteFeedGroup?id=${id}`, {
		headers: authHeader(),
	});
};

export const UpdateFeedGroup = (values) => {
	return axios.put(`/api/Dog/UpdateFeedGroup?id=${values.id}`, values, {
		headers: values.siteId ? { ...authHeader(), siteId: values.siteId } : authHeader(),
	});
};

export const CreatePadDock = (values) => {
	return axios.post(`/api/Paddock/CreatePaddock`, values, {
		headers: values.siteId ? { ...authHeader(), siteId: values.siteId } : authHeader(),
	});
};

export const GetPadDock = (value) => {
	return axios.get(`/api/Paddock/GetPaddocks?showOnlyActivePaddocks=${value}`, {
		headers: authHeader(),
	});
};

export const UpdatePadDock = (values) => {
	return axios.put(`/api/Paddock/UpdatePaddock?id=${values.id}`, values, {
		headers: values.siteId ? { ...authHeader(), siteId: values.siteId } : authHeader(),
	});
};

export const DeletePadDock = (id) => {
	return axios.delete(`/api/Paddock/DeletePaddock?id=${id}`, {
		headers: authHeader(),
	});
};

export const UpdatePrice = (values, siteId) => {
	return axios.post(`/api/Pricing/UpdatePricing?id=${values.id}`, values, {
		headers: siteId ? { ...authHeader(), siteId } : authHeader(),
	});
};

export const GetAllChargesByDogId = (dogId) => {
	return axios.get(`/api/Charge/GetAllChargesByDogId?dogId=${dogId}`, {
		headers: authHeader(),
	});
};

export const uploadReceipt = (values) => {
	const body = new FormData();
	body.append('file', values);
	return axios.post(`/api/Charge/UploadReceipt`, body, {
		headers: authHeader(),
		'Content-Type': 'multipart/form-data',
	});
};

export const createCharges = (values) => {
	return axios.post(`/api/Charge/CreateCharge`, values, {
		headers: authHeader(),
	});
};

export const UpdateCharges = (values) => {
	return axios.put(`/api/Charge/UpdateCharge?id=${values.id}`, values, {
		headers: authHeader(),
	});
};

export const deleteCharges = (id) => {
	return axios.delete(`/api/Charge/DeleteCharge?id=${id}`, {
		headers: authHeader(),
	});
};

export const getStaffById = (id) => {
	return axios.get(`/api/Staff/GetStaffById?Id=${id}`, { headers: authHeader() });
};

export const getDogAttachment = (id) => {
	return axios.get(`/api/Dog/GetDogAttachmentsByDogId/${id}`, {
		headers: authHeader(),
	});
};

export const createDogAttachment = (values, file) => {
	try {
		const body = new FormData();
		body.append('file', file);
		const params = {
			Title: values.title,
			IsVaccine: values.isVaccine,
			Pin: values.pin,
			DogId: values.dogId,
			AttachmentType: values.attachmentType,
			Active: values.active,
		};
		return axios.post(`/api/Dog/CreateDogAttachment`, body, {
			params,
			headers: authHeader(),
			'Content-Type': 'multipart/form-data',
		});
	} catch (e) {
		return false;
	}
};

export const updateDogAttachment = (values, file) => {
	try {
		const body = new FormData();
		body.append('file', file);
		const params = {
			Id: values.id,
			Title: values.title,
			IsVaccine: values.isVaccine,
			Pin: values.pin,
			DogId: values.dogId,
			AttachmentType: values.attachmentType,
			Active: values.active,
		};
		return axios.put(`/api/Dog/UpdateDogAttachment`, body, {
			params,
			headers: authHeader(),
			'Content-Type': 'multipart/form-data',
		});
	} catch (e) {
		return false;
	}
};

export const deleteDogAttachment = (id) => {
	return axios.delete(`/api/Dog/DeleteDogAttachment?id=${id}`, {
		headers: authHeader(),
	});
};

export const getDogAttachmentById = (id) => {
	return axios.get(`/api/Dog/GetDogAttachmentById/${id}`, { headers: authHeader() });
};

export const getSiteAttachment = (id) => {
	return axios.get(`/api/Site/GetSiteAttachments/${id}`, { headers: authHeader() });
};

export const createSiteAttachment = (values) => {
	try {
		const body = new FormData();
		body.append('file', values.attachment);
		const params = {
			Title: values.title,
			attachment: values.attachment,
			Pin: values.pin,
			SiteId: values.siteId,
			Active: values.active,
			AttachmentType: values.attachmentTypeId,
		};
		return axios.post(`/api/Site/CreateSiteAttachment`, body, {
			params,
			headers: authHeader(),
			'Content-Type': 'multipart/form-data',
		});
	} catch (e) {
		return false;
	}
};

export const updateSiteAttachment = (values) => {
	return axios.put(`/api/Site/UpdateSiteAttachment?id=${values.siteId}`, values, {
		headers: authHeader(),
	});
};

export const deleteSiteAttachment = (id) => {
	return axios.delete(`/api/Site/DeleteSiteAttachment?id=${id}`, {
		headers: authHeader(),
	});
};

export const getStaffAttachment = (id) => {
	return axios.get(`/api/Staff/GetStaffAttachments/${id}`, { headers: authHeader() });
};

export const getStaffAttachmentById = (id) => {
	return axios.get(`/api/Staff/GetStaffAttachmentById/${id}`, {
		headers: authHeader(),
	});
};

export const updateStaffAttachment = (values) => {
	return axios.put(`/api/Staff/UpdateStaffAttachment?id=${values.id}`, values, {
		headers: authHeader(),
	});
};

export const createStaffAttachment = (values) => {
	try {
		const body = new FormData();
		body.append('file', values.attachment);
		const params = {
			Title: values.title,
			Pin: values.isTop,
			StaffId: values.staffId,
			Active: values.active,
			AttachmentType: values.attachmentType,
		};
		return axios.post(`/api/Staff/CreateStaffAttachment`, body, {
			params,
			headers: authHeader(),
			'Content-Type': 'multipart/form-data',
		});
	} catch (e) {
		return false;
	}
};

export const deleteStaffAttachment = (id) => {
	return axios.delete(`/api/Staff/DeleteStaffAttachment?staffId=${id}`, {
		headers: authHeader(),
	});
};

export const getVanAttachment = (id) => {
	return axios.get(`/api/Vehicle/GetVehicleAttachments/${id}`, {
		headers: authHeader(),
	});
};

export const GetStaffLeaveCalendar = (values) => {
	if (!values.jobCode && !values.staffId) {
		return axios.get(
			`/api/Staff/GetStaffLeaveCalendar?month=${values.month}&year=${values.year}&siteId=${values.siteId}&isStaffView=${values.isStaffView}`,
			{
				headers: authHeader(),
			},
		);
	}
	return axios.get(
		`/api/Staff/GetStaffLeaveCalendar?month=${values.month}&year=${values.year}&siteId=${
			values.siteId
		}&isStaffView=${values.isStaffView}&staffId=${
			values.staffId ? values.staffId : 0
		}&jobCode=${values.jobCode ? values.jobCode : 0}`,
		{
			headers: authHeader(),
		},
	);
};

export const GetStaffLeaveRequestDashboard = (values) => {
	return axios.get(
		`api/Staff/GetStaffEventCount?leaveType=${values.leaveType}&leaveStatus=${values.leaveStatus}`,
		{
			headers: authHeader(),
		},
	);
};

export const GetStaffLeaveRequest = () => {
	return axios.get(`/api/Staff/GetStaffLeaveRequest`, {
		headers: authHeader(),
	});
};

export const ChangeLeaveStatus = (values, body) => {
	return axios.post(
		`/api/Staff/ChangeLeaveStatus?identifier=${values.identifier}&leaveStatus=${values.leaveStatus}`,
		body,
		{
			headers: authHeader(),
		},
	);
};

export const createVanAttachment = (values) => {
	try {
		const body = new FormData();
		body.append('file', values.attachment);
		const params = {
			Title: values.title,
			Pin: values.isTop,
			VehicleId: values.vehicleId,
			Active: values.active,
			AttachmentType: values.attachmentTypeId,
		};
		return axios.post(`/api/Vehicle/CreateVehicleAttachment`, body, {
			params,
			headers: authHeader(),
			'Content-Type': 'multipart/form-data',
		});
	} catch (e) {
		return false;
	}
};

export const updateVansAttachment = (values) => {
	return axios.put(`/api/Vehicle/UpdateVehicleAttachment?id=${values.vehicleId}`, values, {
		headers: authHeader(),
	});
};

export const deleteVanAttachment = (id) => {
	return axios.delete(`/api/Vehicle/DeleteVehicleAttachment?vehicleId=${id}`, {
		headers: authHeader(),
	});
};

export const deleteVan = (id) => {
	return axios.delete(`/api/Vehicle/DeleteVehicle?id=${id}`, {
		headers: authHeader(),
	});
};

export const SendEmail = (values, params = false) => {
	return axios.post(`/api/EMail/SendEmail?isAllEmailDog=${params}`, values, {
		headers: authHeader(),
	});
};

export const EmailSchedule = (values) => {
	return axios.post(
		`/api/EMail/EmailSchedule?siteId=${values.siteId}&scheduleDate=${values.date}`,
		{
			headers: authHeader(),
		},
	);
};

export const EmailForwardBookingReport = (date) => {
	return axios.post(
		`/api/EMail/EmailForwardBookingReport?bookingDate=${date}`,
		{
			headers: authHeader(),
		},
		{ headers: authHeader() },
	);
};

export const SendEmailAddress = (staffId, values) => {
	return axios.post(`/api/EMail/EmailAddressList?to=${staffId}`, values, {
		headers: authHeader(),
	});
};

export const SendDogsAddressToDriver = (values) => {
	return axios.post(
		`/api/EMail/SendDogsAddressToDriver?siteId=${values.siteId}&routeId=${values.routeId}&scheduleDate=${values.scheduleDate}`,
		{
			headers: authHeader(),
		},
	);
};

export const SendEmailVaccineExpried = (dogId, type) => {
	if (dogId) {
		return axios.post(
			`/api/EMail/SendEmailVaccineExpried?dogId=${dogId}&vaccinationsType=${type}`,
			{
				headers: authHeader(),
			},
		);
	}
	return axios.post(`/api/EMail/SendEmailVaccineExpried`, {
		headers: authHeader(),
	});
};

export const SendEmailVaccineExpring = (dogId, type) => {
	if (dogId) {
		return axios.post(
			`/api/EMail/SendEmailVaccineExpring?dogId=${dogId}&vaccinationsType=${type}`,
			{
				headers: authHeader(),
			},
		);
	}
	return axios.post(`/api/EMail/SendEmailVaccineExpring`, {
		headers: authHeader(),
	});
};

export const DownloadInvoiceByDogAndMonthYear = (values) => {
	return axios({
		url: `/api/Invoice/DownloadInvoiceByDogAndMonthYear?dogId=${values.dogId}&month=${values.month}&year=${values.year}&uploadToDropbox=${values.uploadDropbox}`,
		method: 'POST',
		headers: authHeader(),
		responseType: 'blob',
	});
};

export const ScheduleForm = (values) => {
	return axios({
		url: `/api/Scheduler/ScheduleForm`,
		method: 'POST',
		data: values,
		headers: authHeader(),
		responseType: 'blob',
	});
};

export const ExportPaddock = (values) => {
	return axios.post(`/api/Paddock/ExportPaddock?date=${values.date}&siteId=${values.siteId}`, {
		headers: authHeader(),
	});
};

// export const ExportPaddock = (values) => {
// 	return axios({
// 		url: `/api/Paddock/ExportPaddock?date=${values.date}&siteId=${values.siteId}`,
// 		method: 'POST',
// 		responseType: 'blob',
// 	});
// };

export const ExportXeroMonthReport = (values) => {
	return axios.post(
		`/api/Invoice/ExportXeroMonthReport?month=${values.month}&year=${values.year}&siteId=${values.siteId}`,
		{
			headers: authHeader(),
		},
	);
};

export const EmailDogInvoice = (values) => {
	return axios.post(
		`/api/Invoice/EmailDogInvoice?dogId=${values.dogId}&month=${values.month}&year=${values.year}&uploadToDropbox=${values.upload}`,
		{
			headers: authHeader(),
		},
	);
};

export const BulkSendInvoices = (values) => {
	return axios.post(
		`/api/Invoice/BulkSendInvoices?month=${values.month}&year=${values.year}&siteId=${values.siteId}&uploadToDropbox=${values.upload}`,
		{
			headers: authHeader(),
		},
	);
};

// export const ExportXeroMonthReport = (values) => {
// 	return axios({
// 		url: `/api/Invoice/ExportXeroMonthReport?month=${values.month}&year=${values.year}&siteId=${values.siteId}`,
// 		method: 'POST',
// 		responseType: 'blob',
// 	});
// };

export const ExportMonthReport = (values) => {
	return axios.post(
		`/api/Invoice/ExportMonthReport?month=${values.month}&year=${values.year}&siteId=${values.siteId}`,
		{
			headers: authHeader(),
		},
	);
};

// export const ExportMonthReport = (values) => {
// 	return axios({
// 		url: `/api/Invoice/ExportMonthReport?month=${values.month}&year=${values.year}&siteId=${values.siteId}`,
// 		method: 'POST',
// 		responseType: 'blob',
// 	});
// };
